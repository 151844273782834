<!-- 美丽乡村详情页-->
<template>
  <div class="viliageDetail w-1400">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
          >乡宁荟</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/xiangninghui/sharehouse' }"
          >共享农庄</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ pageData.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="face mt-20">
      <el-row :gutter="40">
        <el-col :span="11">
          <el-carousel trigger="click" height="500px">
            <el-carousel-item
              v-for="item in pageData.photoUrls.split(',')"
              :key="item + 'url'"
            >
              <div class="face-pic">
                <el-image
                  style="width: 100%; height: 100%" fit="cover"
                  :src="item"
                ></el-image>
              </div>
            </el-carousel-item>
          </el-carousel>
          <!-- <div class="face-pic">
              <el-image style="width:100%;height:100%" :src="require('../../../../public/img/xnh/sj1.png')"></el-image>
          </div> -->
        </el-col>
        <el-col :span="13">
          <div class="face-inco">
            <div class="face-price f-label">
              人均消费：{{ pageData.pcConsumption }}
            </div>
            <div class="face-intro">
              <!-- <span> ★★★★★ 5.0分</span> -->
              <el-rate class="rate_star" v-model="pageData.evaluateStar" disabled show-score text-color="#ff9900" score-template="{value}分"></el-rate>
              <span>{{pageData.evaluateNumber}} 条评价</span>
            </div>
            <!--<div class="face-person f-label">联系人：秦美人</div>-->
            <div class="face-phone f-label">预定电话：{{ pageData.telephone }}</div>
            <div class="face-has">
              <div class="f-label">游玩项目:</div>
              <ul class="clearfix">
                <li
                  class="fl"
                  v-for="item in pageData.playItems.split(',')"
                  :key="item + 'has'"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="face-has f-label">地址：{{ pageData.address }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="intro-text mt-20">
      <h3>简介：</h3>
      <div>
        {{ pageData.introduction }}
      </div>
    </div>
    <div class="others mt-20">
      <el-row :gutter="24">
        <el-col :span="16">
          <!--农庄设施-->
          <div class="house">
            <div class="others-title">
              <div class="fl h5">农庄设施</div>
            </div>
            <div class="dev-main warp">
              <div
                v-for="item in devList"
                :key="item.icon"
                class="dev-list"
                :class="{ isHas: 1 }"
              >
                <div class="icon-box">
                  <i class="iconfont" :class="item.icon"></i>
                </div>
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
          <!--农庄政策-->
          <div class="house">
            <div class="others-title">
              <div class="fl h5">农庄政策</div>
            </div>
            <div class="dev-main zc">
              <div>
                入住时间：<span>{{ pageData.checkInTime }}以后</span>
              </div>
              <div>
                最多入住：<span>{{ pageData.maximumStay }}天</span>
              </div>
              <div>
                退房时间：<span>{{ pageData.checkOutTime }}以前</span>
              </div>
            </div>
            <div class="dev-main zc">
              <div>
                宠物：<span>{{
                  pageData.pets ? "可携带宠物" : "不可携带宠物"
                }}</span>
              </div>
              <div>
                最少入住：<span>{{ pageData.minimumStay }}天</span>
              </div>
              <div>
                发票：<span>{{
                  pageData.invoice ? "支持开发票" : "不支持开发票"
                }}</span>
              </div>
            </div>
          </div>
          <!--交通路线-->
          <div class="house mt-20">
            <div class="others-title">
              <div class="fl h5">交通路线</div>
            </div>
            <div class="path">
              <p>自驾路线：{{ pageData.driveRoute }}</p>
              <p>公交路线：{{ pageData.busRoute }}</p>
            </div>
          </div>
          <!--位置与配套-->
          <div class="house mt-20">
            <div class="others-title">
              <div class="fl h5">位置与配套</div>
            </div>
            <div class="position">
              <!-- <el-image style="width:100%;height:100%" :src="require('../../../../public/img/xnh/kayang-map.png')"></el-image>     -->
              <arc-map :title="pageData.name" :center="[pageData.longitude, pageData.latitude]"></arc-map>
              <!-- <baidu-map
                class="map"
                :center="{ lng: pageData.longitude, lat: pageData.latitude }"
                :scroll-wheel-zoom="true"
                :zoom="14"
              >
                <bm-marker
                  :position="{
                    lng: pageData.longitude,
                    lat: pageData.latitude,
                  }"
                  :dragging="true"
                >
                  <bm-label
                    :content="pageData.name"
                    :labelStyle="{
                      color: 'skyblue',
                      fontSize: '16px',
                      border: '1px solid skyblue',
                      transform: 'translateX(-48%)',
                    }"
                    :offset="{ width: 0, height: 30 }"
                  />
                </bm-marker>
              </baidu-map> -->
            </div>
          </div>
          <!--评价功能-->
          <evaluatiton :objectId="$route.query.id" :tableName="'t_s_sharedfarm'" :evaluateStar="pageData.evaluateStar" :keyList="keyList" @sum="getPageData"></evaluatiton>
        </el-col>
        <el-col :span="8">
          <div class="advertising">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门村庄</div>
              <router-link to="/villiagelist">
              <div class="fr more">更多<i class="el-icon-arrow-right"></i></div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in VillagePageData"
                :key="item.id + 'near'"
              >
                <div class="advertising-itme-pic fl">
                    <router-link :to="`/villiagedetail?id=${item.id}`">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls.split(',')[0]"
                  ></el-image>
                  </router-link>
                </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="address">类型：{{ item.villageType }}</div>
                  <!-- <div class="market">
                    <span>湖泊</span>
                    <span>森林</span>
                    <span>耕地</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 附近住宿-->
          <div class="advertising mt-20">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门住宿</div>
              <router-link to="xiangninghui/sharehouse">
              <div class="fr more">更多<i class="el-icon-arrow-right"></i></div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in SharedFarmPageData"
                :key="item.id + 'near'"
              >
                  <div class="advertising-itme-pic fl">
                     <router-link :to="`/redirect?id=${item.id}&path=/sharehousedetail`">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls.split(',')[0]"
                  ></el-image>
                  </router-link>
                  </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="score">类型：{{ item.businessType }}</div>
                  <div class="price buy">价格：{{ item.pcConsumption }}元</div>
                  <!-- <div class="price buy">216条评论 价格：100元</div>
                  <div class="market2">
                    <span>农家饭</span>
                    <span>棋牌</span>
                    <span>演绎</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 附近景点-->
          <div class="advertising mt-20">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门景点</div>
              <router-link to="/scenerySpotList">
                <div class="fr more">
                  更多<i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in ScenerySpotPageData"
                :key="item.id + 'near'"
              >
                <div class="advertising-itme-pic fl">
                    <router-link  :to="`/scenerySpotdetail?id=${item.id}`">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls.split(',')[0]"
                  ></el-image>
                  </router-link>
                </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="score">星级：{{ item.grade }}</div>
                  <div class="price buy">价格：{{ item.price }}元</div>
                  <!-- <div class="market2">
                    <span>游湖</span>
                    <span>棋牌</span>
                    <span>快艇</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {
  GetSharedFarm,
  GetVillagePage,
  GetScenerySpotPage,
  GetSharedFarmPage,
} from "@/api/home";
import evaluatiton from '../../../components/xiangninghui/evaluatiton.vue';
import { mapState } from "vuex";
export default {
  components: { evaluatiton },
  computed:{
    ...mapState({
      lonR:a=>a.lonR,
      latR:a=>a.latR,
    })
  },
  data() {
    return {
      devList: [
        { icon: "icon-tingchechang", name: "停车场" },
        { icon: "icon-wifi", name: "WiFi" },
        { icon: "icon-zhinengmatong", name: "独立卫浴" },
        { icon: "icon-linyu", name: "24小时热水" },
        { icon: "icon-kongtiao", name: "空调" },
        { icon: "icon-xishuyongpin", name: "洗漱用具" },
        { icon: "icon-hanglijicun", name: "行李寄存" },
        { icon: "icon-dianshiji", name: "电视" },
        { icon: "icon-nuanqi", name: "暖气" },
        { icon: "icon-shuaqiazhifu_huaban", name: "刷卡服务" },
        { icon: "icon-chuifengji", name: "吹风机" },
        { icon: "icon-xiedaichongwu", name: "携带宠物" },
      ],
      pageData: { photoUrls: "", playItems: "" },
      params: {
        currentPage: 1,
        pageSize: 3,
        filter: {
          businessType: "精品民宿",
        },
      },
      pageData: {},
      SharedFarmPageData: {},
      ScenerySpotPageData: {},
      VillagePageData: {},
      keyList:{
        '还会再来':0,
        '性价比高':0,
        '环境优美':0,
        '干净整洁':0,
        '服务好':0,
      },
      count:0,
    };
  },
  methods: {
    getPageData() {
      GetSharedFarm(this.$route.query.id).then((res) => {
        this.pageData = res.data.data;
        this.devList = this.devList.filter((item) =>
          res.data.data.facilities.split(",").includes(item.name)
        );
        this.getVillagePage(this.pageData);
        this.getScenerySpotPage(this.pageData);
        this.getSharedFarmPage(this.pageData);
      });
    },
    getSharedFarmPage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 3,
        filter: {
          businessType: "精品民宿",
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetSharedFarmPage(params).then((res) => {
        this.SharedFarmPageData = res.data.data.list;
      });
    },
    getScenerySpotPage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 3,
        filter: {
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetScenerySpotPage(params).then((res) => {
        this.ScenerySpotPageData = res.data.data.list;
      });
    },
    getVillagePage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 3,
        filter: {
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetVillagePage(params).then((res) => {
        this.VillagePageData = res.data.data.list;
      });
    },
  },
  mounted() {
    this.getPageData();
    // this.getSharedFarmPage();
    // this.getScenerySpotPage();
    // this.getVillagePage();
  },
};
</script>
<style scoped>
.rate_star{
  margin-right: 30px;
}
.map {
  height: 500px;
}
.isHas {
  background: #78bff8;
}
.dev-list {
  width: 90px;
  padding-bottom: 10px;
  color: #fff;
  margin-right: 16px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.dev-main.warp {
  flex-wrap: wrap;
  justify-content: left;
}
.zc span {
  color: #777;
}
.icon-box {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
}
.dev-main {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}
.icon-box .iconfont {
  font-size: 40px;
  width: 40px;
  height: 40px;
}
.position {
  padding: 12px;
  width: 100%;
  height: 540px;
  overflow: hidden;
}
.path {
  padding-left: 30px;
}
.path p {
  /* height: 40px; */
  line-height: 40px;
  color: #999;
  font-size: 16px;
}
.others .house-item {
  border: 1px solid #ccc;
  margin-top: 15px;
}
.house-price {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #f9773b;
  text-align: right;
  padding-right: 12px;
}
.house-intro {
  font-size: 16px;
  line-height: 20px;
  text-indent: 24px;
  height: 60px;
  color: #777;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.house-h5 {
  font-size: 18px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
}
.house-info {
  padding: 10px;
}
.house-pic {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.others-title .more:hover {
  color: #1c91ff;
}
.others-title .h5 {
  font-size: 22px;
  color: #0169ca;
}
.others-title {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #ccc;
}
.market2 span:nth-child(1) {
  border: 1px solid #f9773b;
  color: #f9773b;
}
.market2 span:nth-child(2) {
  border: 1px solid #1c91ff;
  color: #1c91ff;
}
.market2 span:nth-child(3) {
  border: 1px solid #33d448;
  color: #33d448;
}
.market2 span {
  padding: 0 8px;
  margin-right: 12px;
}
.score {
  color: #f9773b;
}
.market span {
  padding: 0 8px;
  background: #1c91ff;
  color: #fff;
  margin-left: 12px;
}
.item-info div.buy {
  color: #0169ca;
}
.item-info div {
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-main {
  padding: 0 10px;
  border: 1px solid #ccc;
}
.more:hover {
  color: #eee;
}
.more {
  cursor: pointer;
  font-size: 16px;
}
.advertising-h3 {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  background: #0169ca;
  padding: 0 6px;
}
.advertising-itme {
  margin-top: 12px;
  overflow: hidden;
  width: 100%;
  height: 120px;
}
.advertising-itme-pic {
  width: 170px;
  height: 114px;
}
.item-info {
  width: calc(100% - 180px);
}
.intro-text div {
  font-size: 16px;
  text-indent: 20px;
  line-height: 1.5;
}
.intro-text h3 {
  height: 42px;
  line-height: 42px;
  font-size: 20px;
  font-weight: 600;
}
.intro-text {
  border: 1px solid #ccc;
  padding: 20px 10px;
}
.face-has li {
  background: #0169ca;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
}
.face-has ul {
  padding-left: 30px;
  width: 500px;
}
.face-has li {
  margin-top: 20px;
}
.face-intro{
  display: flex;
}
.face-intro,
.face-has {
  margin: 20px 0;
}
.face-intro span:last-child {
  font-size: 16px;
  color: #1c91ff;
}
.face-intro span:first-child {
  color: #f9773b;
  padding: 10px 0px;
  font-size: 20px;
  margin-right: 20px;
}
.viliageDetail {
  font-family: "微软雅黑";
}
.f-label {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.face-pic >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.face-pic {
  height: 500px;
  width: 100%;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
